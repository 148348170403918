import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 470px;
  max-height: 470px;

  @media screen and (min-width: 980px) {
    justify-content: flex-start;
    align-items: flex-start;
    min-width: min(100vw, 1200px);
    min-height: 410px;
    max-height: 410px;
    padding-left: 50px;
  }

  @media screen and (min-width: 1365px) {
    padding-left: 60px;
    max-width: 1350px;
    min-width: 1350px;
  }
`

export const Title = styled.h1<{ darkColor: boolean, bigTitle: boolean }>`
  color: ${({ darkColor }) => darkColor ? '#212121' : '#FFF'};
  text-shadow: ${({ darkColor }) => darkColor ? '0px 0px 3px #21212170' : '0px 0px 15px #21212170'};
  line-height: 120%;
  max-width: 85%;
  font-size: ${({ bigTitle }) => bigTitle ? '32px' : '40px'};
  text-transform: uppercase;
  text-align: center;
  z-index: 10;
  margin-bottom: 0;
  
  @media screen and (min-width: 980px) {
    text-shadow: ${({ darkColor }) => darkColor ? '0px 0px 3px #21212170' : '0px 0px 15px #21212170'};
    font-weight: 700;
    font-size: 62px;
    z-index: 20;
    justify-self: left;
    text-align: left;
    margin-right: 30px;
    max-width: 640px;
    margin-bottom: 10px;
  }
  @media screen and (min-width: 1080px) {
   font-size: 72px;
 }
`

export const Subtitle = styled.h2<{ darkColor: boolean }>`
  color: ${({ darkColor }) => darkColor ? '#212121' : '#FFF'};
  text-shadow: ${({ darkColor }) => darkColor ? '0px 0px 15px #21212170' : '0px 0px 15px #21212170'};
  line-height: 100%;
  font-size: 20px;
  line-height: 150%;
  font-weight: 400;
  text-align: center;
  margin: 30px 30px;
  z-index: 10;

  @media screen and (min-width: 980px) {
    text-shadow: ${({ darkColor }) => darkColor ? '0px 0px 3px #21212150' : '0px 0px 15px #21212170'};
    font-size: 24px;
    font-weight: 400;
    max-width: 550px;
    text-align: left;
    margin: 30px 0;
  }
`

export const BlurryBackground = styled.div<{ backgroundColor: string, quizHeader: boolean }>`
  position: absolute;
  top: 60px;
  width: 100%;
  height: 530px;
  background-color: ${props => props.backgroundColor};
  opacity: 0.5;
  z-index: 2;
  border: none;

  @media screen and (min-width: 980px) {
    display: ${({ quizHeader }) => quizHeader ? 'none' : ''};
    position: absolute;
    top: 0px;
    left: 0;
    opacity: 0.2;
  }
`

export const QuizButton = styled.a`
  color: #55AADD;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #FFF;
  border: none;
  text-decoration: none;
  cursor: pointer;
  z-index: 10;
  font-size: 14px;
  padding: 8px 19px;
  border-radius: 30px;
  
  ${media.tabMUp} {
    padding: 16px 24px;
    min-width: 160px;
    font-size: 20px;
  }
`