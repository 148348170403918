import React from 'react'
import styled from 'styled-components'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IPost } from '../../../services/interfaces/WordpressServiceDtos'
import { generatePostLink } from '../../../utils/post'

const Wrapper = styled.div`
  min-width: 100%;
  background: linear-gradient(61.22deg, #1175D7 28.65%, #1175D7 59.22%, #3899F9 93.81%);
  height: 470px;
  max-width: 100%;
  display: flex;
  
  @media only screen and (min-width: 970px) {
    height: 410px;
  }

  & > .carousel {
    margin: 0 auto;
    padding: 0;
    height: 470px;
    max-height: 470px;
    max-width: 1500px;
    color: white;

    @media only screen and (min-width: 970px) {
      height: 410px;
    }

    @media only screen and (min-width: 980px) {
      height: 440px;
      padding-top: 15px;
    }

    @media only screen and (min-width: 1024px) {
      padding-top: 0;
    }

    .swiper-button-prev {
      background-image: url('/images/chevron-arrow.webp');
      background-position: 5px 5px;
      background-repeat: no-repeat;
      background-size: 80%;
      transform: rotate(180deg);
    }

    .swiper-button-next {
      background-image: url('/images/chevron-arrow.webp');
      background-position: 5px 5px;
      background-repeat: no-repeat;
      background-size: 80%;
    }

    .swiper-button-prev, .swiper-button-next {
      visibility: hidden;

      @media only screen and (min-width: 970px) {
        color: #1175D7;
        padding: 10px 20px;
        visibility: visible;
        background-color: #FFF;
        border-radius: 50%;
        border: 1px solid #D7EEF4;
        transition: all ease-in-out 300ms;

        &::after {
          content: '';
        }

        &:hover {
          color: #FFF;
        }
        
        &:hover:active {
          color: #D7EEF4;
          filter: brightness(90%);
        }
      }
    }

    .swiper-pagination {
      visibility: visible;
      position: absolute;
      bottom: 24px;

      @media only screen and (min-width: 970px) {
        visibility: hidden;
      }
    }

    .swiper-pagination-bullet {
      background-color: white;
    }
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 10;
  padding: 0 15px;
  margin: 16px auto;
  width: 100%;

  @media only screen and (min-width: 800px) {
    max-width: 1200px;
    min-width: 20vw;
    margin-right: 0;
    padding: 0;
    overflow: visible;
    justify-content: center;
  }
`

const TitleContainer = styled.h1`
  font-style: normal;
  color: #FFFFFF;
  text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  margin: 0 0 12px;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  
  @media only screen and (min-width: 800px) {
    margin: 0 0 16px;
    min-width: min(45vw, 700px);
    max-width: 700px;
    font-weight: 700;
    font-size: 50px;
    line-height: 110%;
  }
`

const SubtitleContainer = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #FFFFFF;
  overflow: clip;
  margin: 0;
  max-height: 120px;
  
  @media only screen and (min-width: 800px) {
    margin: 16px 0 0;
    min-width: min(20vw, 360px);
    max-width: min(35vw, 500px);
  }
`

const Container = styled.a`
  display: flex;
  max-width: 100vw;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;

  @media only screen and (min-width: 800px) {
    flex-direction: row-reverse;
    max-width: 900px;
    margin: 0 auto;
    padding: 0 min(30px, calc(10% - 20px));
  }

  @media only screen and (min-width: 970px) {
    padding: 0 85px;
    flex-direction: row-reverse;
    max-width: 1400px;
  }
`

const ImageContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 35px 0 0;
  
  @media only screen and (min-width: 800px) {
    height: 330px;
    align-items: flex-end;
    border-radius: 10px;
    height: 410px;
    margin: 0;
  }

  & > img {
    border-radius: 10px;
    width: calc(100% - 24px);
    max-width: 100%;
    object-fit: cover;
    aspect-ratio: 16 / 9;
    max-height: 200px;
    
    @media only screen and (min-width: 800px) {
      width: unset;
      max-width: 45vw;
      height: 355px;
      filter: brightness(80%);
      margin: 0;
      min-height: 355px;
      object-fit: cover;
      aspect-ratio: 16 / 9;
    }
  }
`

export interface ICarouselProps {
  children: IPost[]
}

export const CarouselSlider = (props: ICarouselProps) => {
  const renderItens = () => {
    const renderList = props.children.length > 5 ? props.children.slice(0, 5) : props.children

    return renderList.map((value, index) => {
      return (
        <SwiperSlide key={`key-${index}-${value.title}`}>
          <Container href={generatePostLink(value.categorySlug, value.slug)}>
            <ImageContainer>
              <img src={value.image_webp || value.image} loading="lazy" alt={value.title} />
            </ImageContainer>
            <TextContainer>
              <TitleContainer>{value.title}</TitleContainer>
              <SubtitleContainer>{value.carousel_subtitle}</SubtitleContainer>
            </TextContainer>
          </Container>
        </SwiperSlide>
      )
    })
  }

  return (
    <>
      {props.children.length > 2 &&
        <Wrapper data-testid="container-wrapper" id="anchor">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: false,
              enabled: true
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="carousel"
          >
            {renderItens()}
          </Swiper>
        </Wrapper>
      }
    </>
  )
}
