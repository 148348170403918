import styled from 'styled-components'

export const BackgroundColor = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 470px;
  max-height: 470px;
  min-width: 100%;
  max-width: 100%;
  
  @media screen and (min-width: 980px) {
    display: flex;
    flex-direction: row;
    min-height: 410px;
    max-height: 410px;
  }
`
export const OverflowHandler = styled.div`
  position: absolute;
  top: 580px;
  width: 100%;
  height: 20px;
  background-color: #FFF;
  z-index: 9;

  @media screen and (min-width: 980px) {
    display: none;
  }
`